import { DispositivoType } from "@/api";

const modelliMacchinario = [
  "DISOLEATORE GFLD 3000",
  "GFL - BANCO DI FLUSSAGGIO",
  "GFL - CENTRALE STOCCAGGIO OLIO",
  "GFL - CENTRALINA PER EMULSIONE",
  "GFL D 1000",
  "GFL D 3000",
  "GFL DF 12000",
  "GFL HC 400",
  "GFL HCF 100",
  "GFL HCF 200",
  "GFL HCF 300",
  "GFL HCFT 200",
  "GFL HCFT 400",
  "GFL HCFTA 200",
  "GFL TFL 500",
  "GFL TFL 700",
  "GFL TFLC 500",
  "GFL TFLD 500",
  "GFL TFLD 550",
  "GFL TFLE 500",
  "GFL TFLEA",
  "GFL TFLG 700",
  "GFL TFLP 500",
  "GFL TFLV 300",
  "GFL TH 2000",
  "GFL TH 4000",
  "GFL ZFM 100",
] as const;

const modelliSensori = [
  "GFL SENSOR PT",
  "GFL SENSOR WT",
  "GFL MONITORING WT",
  "GFL MONITORING WTP",
  "GFL PARTICLE_MONITORING",
] as const;

export const modelliDispositiviByType: {
  [tipo in DispositivoType]:
    | []
    | typeof modelliMacchinario
    | typeof modelliSensori;
} = {
  unknown: [],
  macchinario: modelliMacchinario,
  sensore: modelliSensori,
};

export const immaginiByModello: {
  [modello in
    | (typeof modelliMacchinario)[number]
    | (typeof modelliSensori)[number]]?: string;
} = {
  // "GFL - CENTRALE STOCCAGGIO OLIO": "/immagini-dispositivi/1.jpg",
  // "GFL - BANCO DI FLUSSAGGIO": "/immagini-dispositivi/1.jpg",
  // "GFL DF 12000": "/immagini-dispositivi/2.jpg",
  // "GFL TFLV 300": "/immagini-dispositivi/3.png",
  "GFL SENSOR WT": "/immagini-dispositivi/SENSOR_WT_stilizzato.png",
  // "GFL MONITORING WT": "/immagini-dispositivi/5.png",
  "GFL TFL 700": "/immagini-dispositivi/TFL_700_stilizzato.png",
  "GFL TFLEA": "/immagini-dispositivi/TFL_700_stilizzato.png",
  "GFL D 3000": "/immagini-dispositivi/D3000_stilizzato.png",
  "GFL TFLD 550": "/immagini-dispositivi/TFLD_550_stilizzato.png",
  "GFL TH 4000": "/immagini-dispositivi/TH_4000_stilizzato.png",
  "GFL SENSOR PT": "/immagini-dispositivi/SENSOR_PT_stilizzato.png",
  "GFL MONITORING WTP": "/immagini-dispositivi/MONITORING_WTP_stilizzato.png",
  "GFL PARTICLE_MONITORING":
    "/immagini-dispositivi/PARTICLE_MONITORING_stilizzato.png",
};
